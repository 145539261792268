export default {
    data() {
      return {
        msg: 'Hello World',
      }
    },
    created: function () {
    },
    methods: {
      displayMessage: function () {
      },
    },
  }